const Newsletters = [
  {
    publishedAt: "2024-07-26",
    url: "http://eepurl.com/iUabPU",
    title: "Qu’est-ce qui fait courir les designers ?",
  },
  {
    publishedAt: "2024-07-09",
    url: "http://eepurl.com/iTjtK2",
    title: "Qu’est-ce que Prouvé aurait créé avec l’IA ?",
  },
  {
    publishedAt: "2024-06-25",
    url: "http://eepurl.com/iSs462",
    title: "Comment faire du beau en design ?",
  },
  {
    publishedAt: "2024-06-11",
    url: "http://eepurl.com/iRsIts",
    title: "Comment les lunettes sont devenues un objet de caractère ?",
  },
  {
    publishedAt: "2024-05-28",
    url: "http://eepurl.com/iQJHLs",
    title: "Qu'est-ce qui est rose et sert à orienter ?",
  },
  {
    publishedAt: "2024-05-14",
    url: "http://eepurl.com/iPHF76",
    title:
      "Vous allez vraiment la jeter ? Les dessous de la fabrication du mobilier de Maximum",
  },
  {
    publishedAt: "2024-04-30",
    url: "http://eepurl.com/iOS4Lg",
    title: "Pourquoi l'imperfection rend l’objet attachant ?",
  },
  {
    publishedAt: "2024-04-11",
    url: "http://eepurl.com/iNF2Og",
    title:
      "Comment donner forme à la lumière ? Feat. Baptiste Meyniel et Marie Levoyet",
  },
  {
    publishedAt: "2024-03-26",
    url: "http://eepurl.com/iMJYNA",
    title: "Quels seront les designers médaillés ?",
  },
  {
    publishedAt: "2024-03-12",
    url: "http://eepurl.com/iLMBQM",
    title: "C’est quoi le minimalisme en design ?",
  },
  {
    publishedAt: "2024-02-27",
    url: "http://eepurl.com/iKOlIg",
    title: "Où sont passés les designers du Like ?",
  },
  {
    publishedAt: "2024-02-20",
    url: "http://eepurl.com/iKlvUg",
    title: "Est-ce que le designer a besoin de retours utilisateurs ?",
  },
  {
    publishedAt: "2024-02-13",
    url: "http://eepurl.com/iJIsi6",
    title: "Pourquoi ne rangeons-nous plus rien sur nos étagères ?",
  },
  {
    publishedAt: "2024-01-23",
    url: "http://eepurl.com/iIowBI",
    title:
      "Pourquoi est-il si compliqué de faire une chaise ? Les dessous de la fabrication de la chauffeuse de Guillaume Delvigne pour La chaise Française",
  },
  {
    publishedAt: "2024-01-09",
    url: "http://eepurl.com/iHstrU",
    title: "Pourquoi certaines couleurs nous font chavirer ?",
  },
  {
    publishedAt: "2023-12-27",
    url: "http://eepurl.com/iGROAY",
    title: "Comment le design chamboule tout, même notre smartphone ?",
  },
  {
    publishedAt: "2023-12-12",
    url: "http://eepurl.com/iFQRk2",
    title:
      "Est-ce que vraiment la laideur se vend mal ? Feat. Anthony Lebossé, Studio 5•5",
  },
  {
    publishedAt: "2023-11-28",
    url: "http://eepurl.com/iEOTz2",
    title: "Pourquoi le design distribue-t-il autant de cœurs ?",
  },
  {
    publishedAt: "2023-11-14",
    url: "http://eepurl.com/iDA6rw",
    title: "L’accoudoir est-il vraiment designé pour être partagé ?",
  },
  {
    publishedAt: "2023-10-31",
    url: "http://eepurl.com/iCDPFw",
    title: "Est-ce qu'il y a besoin de designers pour tout ?",
  },
  {
    publishedAt: "2023-10-17",
    url: "http://eepurl.com/iBPQCc",
    title: "Comment imprimer des poils ? Rencontre avec le studio BOLD",
  },
  {
    publishedAt: "2023-10-10",
    url: "http://eepurl.com/iBmuTk",
    title: "Les objets sont-ils trop bavards ?",
  },
  {
    publishedAt: "2023-10-03",
    url: "http://eepurl.com/iAUWBE",
    title: "Le designer doit-il se plier en quatre ?",
  },
  {
    publishedAt: "2023-09-26",
    url: "http://eepurl.com/iAgdCE",
    title: "Le designer est-il à l'heure ?",
  },
  {
    publishedAt: "2023-09-12",
    url: "http://eepurl.com/izqdm2",
    title: "Les designers font-ils la pluie et le beau temps ?",
  },
  {
    publishedAt: "2023-07-18",
    url: "http://eepurl.com/ivz1oE",
    title: "Quel design pour les Jeux de Paris 2024 ?",
  },
  {
    publishedAt: "2023-07-12",
    url: "http://eepurl.com/ivew4c",
    title: "Pourquoi le gris l’emporte toujours dans le design ?",
  },
  {
    publishedAt: "2023-07-02",
    url: "http://eepurl.com/iuKxjk",
    title: "Est-ce que l’Apple Vision Pro sonne la fin du bureau ?",
  },
  {
    publishedAt: "2023-06-20",
    url: "http://eepurl.com/itJgE-/",
    title: "Comment crée-t-on un tube ?",
  },
  {
    publishedAt: "2023-06-13",
    url: "http://eepurl.com/itd-TY",
    title: "Pourquoi créer de nouvelles chaises ?",
  },
  {
    publishedAt: "2023-06-06",
    url: "http://eepurl.com/isGWw2",
    title: "Est-ce que design peut rimer avec humour ?",
  },
  {
    publishedAt: "2023-05-30",
    url: "http://eepurl.com/isbOvo",
    title: "Pourquoi penser à cette lampe quand on vous parle Bauhaus ?",
  },
  {
    publishedAt: "2023-05-23",
    url: "http://eepurl.com/irE4Oo",
    title:
      "Pourquoi y-a-t-il autant de boutons dans une voiture que de trous dans le gruyère ?",
  },
  {
    publishedAt: "2023-05-16",
    url: "http://eepurl.com/irhp42",
    title: "Comment la vache Patience est devenue design universel ?",
  },
  {
    publishedAt: "2023-05-10",
    url: "http://eepurl.com/iqM_Bw",
    title: "Tops ou flops, qu’est-ce qu’un objet culte ?",
  },
  {
    publishedAt: "2023-05-03",
    url: "http://eepurl.com/iqgGfA",
    title: "Où sont les Maestri aujourd'hui?",
  },
  {
    publishedAt: "2023-04-25",
    url: "http://eepurl.com/io-Epg",
    title:
      "Est-ce que le revirement de Marie Kondo va mettre à mal l'outil Notion ?",
  },
  {
    publishedAt: "2023-04-18",
    url: "http://eepurl.com/ioA5Cw",
    title: "Pourquoi une lampe de chevet dans le TGV ?",
  },
];

export default Newsletters;
